<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Ajout d’un membre de la commission (ouverture/évaluation/négociation)</v-card-title>
          <v-card-text>
            <v-card-text>
              <v-select
                :items="this.$store.getters['Auth/getComUserList']"
                label="Sélectionner un membre de la commission"
                v-model="member.user"
                item-text="firstname"
                item-value="id"
              ></v-select>
              <v-select
                :items="this.$store.getters['Auth/get_all_legal_form'].member_types"
                label="Sélectionner la qualité du membre de la commission (Président/Rapporteur/Membre)"
                v-model="member.type"
                item-text="description"
                item-value="status"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="addMember" color="primary" right>
                <v-icon class="mr-2">mdi-account-plus</v-icon>Ajouter
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>Aperçu des  membres de la commission (ouverture/évaluation/négociation)</v-card-title>
          <v-card-text>
            <organization-chart
              v-if="this.$store.getters['Auth/get_selected_com']"
              :datasource="this.$store.getters['Auth/get_selected_com'].chart"
            >
            </organization-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import { mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    OrganizationChart
  },
  data() {
    return {
      members: [],
      commission: {
        title: "",
        type: ""
      },
      bread: [
        {
          text: "Tableau de bord",
          disabled: false,
          href: "/dashboard"
        },
        {
          text: "Commission",
          disabled: false
        },
        {
          text: "Détails de la commission",
          disabled: true
        }
      ],
      member: {
        user: 0,
        type: ""
      }
    };
  },
  created() {
    this.$store.dispatch("Auth/loadAllComUser");
    this.loadComById(this.id);
    // console.log("Membres", this.$store.getters['Auth/get_selected_com'].then( response => {
    //   console.log(response)
    // }));
  },
  // computed(){
  //   console.log("memebers", this.$store.getters['Auth/get_selected_com'].members);
  //   let conteneur = this.$store.getters['Auth/get_selected_com'].members;
  //   this.$store.getters['Auth/get_selected_com'].members.forEach((value) => {
  //       console.log(value)
  //       conteneur.unshift(value);
  //   });
  // },
  methods: {
    ...mapActions({
      loadComById: "Auth/loadComById",
      add_com_member: "Auth/add_com_member",
      add_com: "Auth/add_com",
      loadAllCom: "Auth/loadAllCom"
    }),
    addCommission() {
      const data = new FormData();
      data.append("father", this.id);
      data.append("title", this.commission.title);
      data.append("type", this.commission.type);
      this.add_com(data);

      this.commission.title = "";
      this.commission.type = "";
    },
    addMember() {
      const data = new FormData();
      data.append("user", this.member.user);
      data.append("commission", this.id);
      data.append("type", this.member.type);
      this.add_com_member(data);
      this.member.user = "";
      this.member.type = "";
    }
  }
};
</script>
<style>
.orgchart-container {
  border: none;
}
.orgchart .node .title {
  background-color: rgb(3, 89, 173);
}
.orgchart .lines .downLine {
  background-color: rgb(63, 211, 199);
}
.orgchart .lines .topLine {
  border-top: 2px solid rgb(63, 211, 199);
}
.orgchart .lines .rightLine {
  border-right: 1px solid rgb(63, 211, 199);
}
.v-application .title {
  font-size: 0.8rem !important;
}
</style>
