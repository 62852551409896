<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-text-field
              filled
              label="Saisir le type de commissions (ouverture/évaluation/négociation) "
              v-model="commission.title"
              persistent-hint
            ></v-text-field>
            <v-select
              :items="this.$store.getters['Auth/get_all_legal_form'].commission_types"
              label="Sélectionner le type de commission"
              v-model="commission.type"
              item-text="description"
              item-value="status"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addCommission" color="primary" right>
              <v-icon class="mr-2">mdi-account-multiple</v-icon>Créer la commission
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="mx-auto">
          <v-card-title>
            <h4>Création et configuration des membres des différentes commissions d’ouverture, d’évaluation (Offres /Propositions /Plis) et de négociation</h4>
            <v-spacer></v-spacer
            ><v-chip class="ma-2">{{ this.$store.getters["Auth/getCom"].length }}</v-chip>
          </v-card-title>

          <v-divider></v-divider>
          <v-virtual-scroll
            :items="this.$store.getters['Auth/getCom']"
            :item-height="80"
            height="400"
          >
            <template v-slot="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon large>mdi-account-group</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip small label>{{ item.type.title }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    item.createdAt | moment("Do MMMM YYYY à HH:mm:ss")
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click="goto(item.id)" depressed small>
                    CONFIGURER LES MEMBRES DE LA COMMISSION
                    <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    info: false,
    showMessage: [],
    commission: {
      title: "",
      type: ""
    },
    password: "Password",
    bread: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Commission",
        disabled: true
      }
    ],
    show: false,
    marker: true,
    iconIndex: 0
  }),

  computed: {
    icon() {
      return this.icons[this.iconIndex];
    },
    items() {
      const namesLength = this.names.length;
      const surnamesLength = this.surnames.length;
      const colorsLength = this.colors.length;

      return Array.from({ length: 10000 }, () => {
        const name = this.names[this.genRandomIndex(namesLength)];
        const surname = this.surnames[this.genRandomIndex(surnamesLength)];

        return {
          color: this.colors[this.genRandomIndex(colorsLength)],
          fullName: `${name} ${surname}`,
          initials: `${name[0]} ${surname[0]}`
        };
      });
    }
  },
  created() {
    this.loadAllCom();
  },
  methods: {
    ...mapActions({
      add_com: "Auth/add_com",
      loadAllCom: "Auth/loadAllCom"
    }),
    goto(val) {
      this.$router.push({ name: "showCommission", params: { id: val } });
    },
    addCommission() {
      const data = new FormData();
      data.append("title", this.commission.title);
      data.append("type", this.commission.type);
      this.add_com(data);

      this.commission.title = "";
      this.commission.type = "";
    },
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    }
  }
};
</script>
